
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "./survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import avrtLogo from '../assets/images/avrt-logo.png';
Amplify.configure(awsExports);
// Amplify.configure(awsExports);
const PatientHeader = (props) => {
    const [idToken, setIdToken] = useState('')

    useEffect(() => {
        Auth.currentSession().then((res) => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            //You can print them to see the full objects
            setIdToken(res.getIdToken().jwtToken)

        });
    }, []);
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (
        <div>

            <Grid container spacing={2} alignItems="center" style={{backgroundColor: "#ccc"}}>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                    <a href='/patient'>
                        <img src={avrtLogo} className="header-logo" />
                    </a>

                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Button color="primary" variant="contained" onClick={() => { window.location.href='/patient' }}>
                        Home
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                    <Button color="primary" variant="contained" onClick={() => { signOut(); }}>
                        Sign out
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
export default withAuthenticator(PatientHeader)
