
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "../components/survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import PatientHeader from "../components/PatientHeader"
import awsvars from "../aws.json"
import axios from "axios";

import Box from '@mui/material/Box';

import CircularProgressWithLabel from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import awsGlobal from "../aws-global"
Amplify.configure(awsExports);

const PatientSurvey = (props) => {
    // for Amplify to work manually
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [idToken, setIdToken] = useState('')
    const [surveyData, setSurveyData] = useState({})
    const [surveyPage, setSurveyPage] = useState(0)
    const [complete, setComplete] = useState(50)
    const [loaded, setLoaded] = useState(false)
    const [cognito, setCognito] = useState({})
    const [region, setRegion] = useState('')
    const [awsUrl, setAwsUrl] = useState('')
    const [awsSurveyUrl, setSurveyUrl] = useState('')
    const [totalActions, setTotalActions2] = useState(0)
    useEffect(() => {
        readTaskData(idToken)
    }, [awsUrl, idToken])

    useEffect(() => {
        if (region) {
            setAwsUrl(awsGlobal.aws_json_patient[awsGlobal.env][region].api_gw_url)
            setSurveyUrl(awsGlobal.aws_json_patient[awsGlobal.env][region].api_gw_survey_url)
        }
    }, [region, idToken])
    useEffect(() => {
        if (authState === undefined) {
            Auth.currentAuthenticatedUser().then(authData => {
                setAuthState(AuthState.SignedIn);
                setUser(authData);
            });
        }
        onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            Auth.currentSession().then((res) => {
                setIdToken(res.getIdToken().jwtToken)
                setRegion(res.getIdToken().payload['custom:region_code'])
                setCognito(res.getIdToken().payload)

            });
        });

    }, [authState]);
    useEffect(() => {
        if (surveyData !== [] && totalActions > 0) {
            var complete = surveyPage * 100 / totalActions
            if (!complete) {
                complete = 0
            }
            setComplete(complete)
        }

    }, [surveyPage, totalActions]);

    const setTotalActions = (v) => {
        if (v && v > 0) {
            setTotalActions2(v)
        }

    }
    const setPage = (p) => {
        setSurveyPage(p)
    }
    async function readTaskData(auth) {
        const server = await axios.get(awsSurveyUrl + '/patient_task?uuid=' + params.uuid, { headers: { Authorization: 'Bearer ' + auth, 'Content-Type': 'application/json' } });
        if (server.data.status === 'done') {
            console.log("already completed")
        }
        setSurveyData(server.data)
        setTotalActions(server.data.total_actions)
        setLoaded(true)
    }
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return authState === AuthState.SignedIn && user ? (
        <div>
            <PatientHeader />
            <br />
            {loaded ?
                <>

                    {(surveyData.status === 'done') ?

                        <div>
                            <Typography variant="h4" gutterBottom>
                                This survey has already been completed - click Home to return to your home page
                            </Typography>


                        </div>
                        : <>
                            <h2>{surveyData.survey_name}</h2>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={complete} />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                        complete,
                                    )}%`}</Typography>
                                </Box>
                            </Box>

                            <SurveyContainer auth={idToken} uuid={params.uuid} survey_id={params.survey_id} setPage={setPage} setComplete={setComplete} loaded={loaded} awsSurveyUrl={awsSurveyUrl} />
                        </>
                    }

                </> : <>< CircularProgress /></>}

        </div>

    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn headerText="AVRT Patient Portal sign-in" slot="sign-in" hideSignUp></AmplifySignIn>
        </AmplifyAuthenticator>
    );
}
export default PatientSurvey
