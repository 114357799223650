import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
// here are the events for Survey https://surveyjs.io/Documentation/Library/?id=surveymodel#onValueChanged

// to be able to cache data the easiest way to do it is:
// locally read the latest answer set
// compare that with the last written set
// if different then write the cache to the user record
// this keeps all the data on the client side and reduces our data needs
// do this every 10 seconds and we pretty much won't lose much

// now need to start making this test survey be data driven so:
// - get the survey ID from a parameter
// - get an AVRT session ID from the parameter too - this identifies this instance of the survey - most likely just epoch time when we initiated the survey fill request
// - we get the epoch time in the screen above on the 'things to do' - so this comes from the user profile
//    - somewhere we will have to add the need for the survey to be completed
//    - store that ID in the patient record so it's used until the survey is completed
//    - pass that on
// - probably don't need PostId as we capture the data ourselves
// - use the survey ID + epoch time to write to the cache
const SurveyContainer = (props) => {

    const [surveyjs, setSurveyJs] = useState()
    const [loaded, setLoaded] = useState(false)
    var cache = {}
    var cacheLoaded = false

    async function syncCache(survey) {
        await triggerCache(survey)


        setTimeout(function () { syncCache(survey); }, 5000)
    }

    async function triggerCache(survey) {
        if (!cacheLoaded) {
            console.log('cache not yet loaded')
            // mitigates against multiple page loads that dump the cache
            return
        }

        var current = { data: survey.data, page: survey.currentPageNo }
        props.setPage(survey.currentPageNo)
        var xx = JSON.stringify(current)
        if (xx !== cache) {
            cache = current
            const r = await axios.put(props.awsSurveyUrl + '/patient_task?action=cache_update&uuid=' + props.uuid, cache, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
        }
    }


    async function readCache(survey) {
        const cached = await axios.get(props.awsSurveyUrl + '/patient_task?uuid=' + props.uuid, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
        if (cached.data.cache) {
            var server_cache = JSON.parse(cached.data.cache).data
            var page_num = JSON.parse(cached.data.cache).page
            survey.mergeData(server_cache)
            survey.currentPageNo = page_num
            props.setPage(page_num)
        }
        cacheLoaded = true
        syncCache(survey)
        setLoaded(true)
    }
    async function sendDataToServer(d) {
        props.setComplete(100)
        d['uuid'] = props.uuid
        axios.post(props.awsSurveyUrl + '/patient_submit_survey?survey_id=' + props.survey_id, d, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
    }


    useEffect(() => {
        if (!props.auth && !props.awsSurveyUrl) {
            return
        }
        if (loaded) {
            return
        }
        Survey
            .StylesManager
            .applyTheme("modern");


        axios.get(props.awsSurveyUrl + '/surveyjs_read?survey_id=' + props.survey_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } })
            .then((response) => {
                const survey = new Survey.Model(response.data);
                setSurveyJs(survey)
                survey.onComplete.add(function (survey_data) {
                    console.log(survey_data)
                    sendDataToServer(survey_data.data)
                })
                survey.onCurrentPageChanged.add(function (survey, options) { triggerCache(survey) })
                readCache(survey)
            })
    }, [props.auth, props.awsSurveyUrl]);

    return (
        <div>

            {(surveyjs && props.loaded && loaded) ?
                <Survey.Survey model={surveyjs} />
                : <>< CircularProgress /> </>}
        </div >

    );
}

export default SurveyContainer;